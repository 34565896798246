<template>
	<base-nav
		container-classes="container-fluid"
		class="navbar-top border-bottom navbar-expand"
	>
		<!-- Navbar links -->
		<b-navbar-nav class="align-items-center ml-md-auto">
			<!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
			<li class="nav-item d-xl-none">
				<!-- Sidenav toggler -->
				<div
					class="pr-3 sidenav-toggler"
					:class="{
						active: $sidebar.showSidebar,
						'sidenav-toggler-dark': type === 'default',
						'sidenav-toggler-light': type === 'light',
					}"
					@click="toggleSidebar"
				>
					<div class="sidenav-toggler-inner">
						<i class="sidenav-toggler-line"></i>
						<i class="sidenav-toggler-line"></i>
						<i class="sidenav-toggler-line"></i>
					</div>
				</div>
			</li>
		</b-navbar-nav>
	</base-nav>
</template>
<script>
export default {
	props: {
		type: {
			type: String,
			default: 'default', // default|light
			description: 'Look of the dashboard navbar. Default (Green) or light (gray)',
		},
	},
	data() {
		return {};
	},
	methods: {
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
	},
};
</script>
